import React, { useState, useEffect } from "react";
import {
    Card,
    CardBody,
    CardTitle,
    Row,
    Col,
    Button,
    FormGroup,
    Input
} from "reactstrap";
import SuspenseComponentText from '../../../views/components/SuspenseComponentText';
import useTimeout from "hooks/useTimeout";
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { postDeviceConfigCommand, getDeviceParameter } from "API";
import { commands } from "variables/commands";
// reactstrap components
const DeviceConfigCommandRemotePrice = ({ serial }) => {
    const [currentPrice, setCurrentPrice] = useState(null);
    const [price, setPrice] = useState("");
    const [wrongPrice, setWrongPrice] = useState(true);
    const [currentDeposit, setCurrentDeposit] = useState("");
    const [tooSmallPrice, setTooSmallPrice] = useState(null);
    useEffect(() => {
        const numericValue = Number(price);
        const numericValueCurrentPrice = Number(currentDeposit);
        if (numericValue >= 0 && !isNaN(numericValue)) {
            setWrongPrice(false)
        } else {
            setWrongPrice(true)
        }
        if (numericValue >= numericValueCurrentPrice) {
            setTooSmallPrice(false)
        } else {
            setTooSmallPrice(true)
        }
    }, [price, currentDeposit])


    const dataConfigDeposit = useQuery('ConfigDeposit', () => getDeviceParameter(serial, commands.deposit_value_get), {
        refetchOnWindowFocus: false,
        onSuccess: (data) => {

            setCurrentDeposit(data.data.value)

        }
    });

    const dataConfigPrice = useQuery('ConfigPrice', () => getDeviceParameter(serial, commands.price), {
        refetchOnWindowFocus: false,
        onSuccess: (data) => {

            setCurrentPrice(data.data.value)

        }
    });


    const handlePriceChange = (event) => {
        setPrice(event.target.value);
    };

    const queryClient = useQueryClient()
    const { mutate: mutationConfigCommand, isLoading: mutationConfigCommandLoading, isError: mutationConfigCommandError, isSuccess: mutationConfigCommandSuccess, reset: mutationConfigCommandReset } = useMutation(
        {
            mutationFn: postDeviceConfigCommand,
            onSuccess: () => {
                // Invalidate and refetch
                timeoutClearDataPost();
                queryClient.invalidateQueries({ queryKey: ['Commands'] })
                queryClient.invalidateQueries({ queryKey: ['ConfigPrice'] })
                queryClient.invalidateQueries({ queryKey: ['ConfigDeposit'] })


            },
            onError: () => {
                // Invalidate and refetch
                timeoutClearDataPost();
                queryClient.invalidateQueries({ queryKey: ['Commands'] })

            },
        }
    );

    const handleSubmit = (event) => {
        event.preventDefault();
        if (mutationConfigCommandError === true || mutationConfigCommandLoading === true) {
            return false
        }

        const numericValue = Number(price);

        if (numericValue >= 0 && !isNaN(numericValue)) {
            mutationConfigCommand({
                serial: serial,
                commandName: commands.price,
                commandValue: price
            })
        } else {
            return false;
        }
    }

    const [timeoutClearDataPost] = useTimeout(() => {
        setPrice("")
        mutationConfigCommandReset();
    }, 3000);


    return (
        <>
            <Col lg="4" md="4">
                <Card className="card-stats">
                    <CardBody>
                        <Row>
                            <Col xs="3">
                                <div className="info-icon text-center icon-success">
                                    <i className="tim-icons icon-coins" />
                                </div>
                            </Col>
                            <Col xs="9">
                                <div className="numbers">
                                    <p className="card-category">Command</p>
                                    <CardTitle tag="h4">

                                        {currentPrice === null ? 'Change Price' : `Change Price : ${currentPrice}`}
                                    </CardTitle>
                                </div>
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col md='12' lg='6' className="flex-el-bottom-center">
                                <Input
                                    type="number"
                                    value={price}
                                    onChange={handlePriceChange}
                                    min={0}
                                />
                            </Col>
                            <Col md='12' lg='6' className="date-submit-center">
                                <div className='submit-button-row'>
                                    <Button className="btn-fill" disabled={tooSmallPrice || wrongPrice || price === "" ? true : false} color={mutationConfigCommandError ? "danger" : mutationConfigCommandSuccess ? "success" : 'info'} type="submit"
                                        onClick={(event) => handleSubmit(event)}
                                    >
                                        {mutationConfigCommandLoading ? <SuspenseComponentText /> :
                                            mutationConfigCommandError ? (
                                                <div>
                                                    Failure <i className="tim-icons icon-simple-remove" />
                                                </div>
                                            ) : mutationConfigCommandSuccess ? (
                                                <div>
                                                    Success <i className="tim-icons icon-check-2" />
                                                </div>
                                            ) :
                                                `Submit`
                                        }
                                    </Button>
                                </div>
                            </Col>

                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </>
    )
}

export default DeviceConfigCommandRemotePrice;