/*!

=========================================================
* Black Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// import VectorMap from "views/maps/VectorMap.js";
// import GoogleMaps from "views/maps/GoogleMaps.js";
// import FullScreenMap from "views/maps/FullScreenMap.js";
// import ReactTables from "views/tables/ReactTables.js";
// import RegularTables from "views/tables/RegularTables.js";
// import ExtendedTables from "views/tables/ExtendedTables.js";
// import Wizard from "views/forms/Wizard.js";
// import ValidationForms from "views/forms/ValidationForms.js";
// import ExtendedForms from "views/forms/ExtendedForms.js";
// import RegularForms from "views/forms/RegularForms.js";
// import Calendar from "views/Calendar.js";
// import Widgets from "views/Widgets.js";
// import Charts from "views/Charts.js";
// import Buttons from "views/components/Buttons.js";
// import SweetAlert from "views/components/SweetAlert.js";
// import Notifications from "views/components/Notifications.js";
// import Grid from "views/components/Grid.js";
// import Typography from "views/components/Typography.js";
// import Panels from "views/components/Panels.js";
// import Icons from "views/components/Icons.js";
// import Pricing from "views/pages/Pricing.js";
// import Register from "views/pages/Register.js";
// import Timeline from "views/pages/Timeline.js";
// import User from "views/pages/User.js";
// import Rtl from "views/pages/Rtl.js";
// import Lock from "views/pages/Lock.js";
import Dashboard from "views/Dashboard.js";
import Configuration from "views/pages/Configuration/Configuration.js";
import ReturnPerson from "views/pages/ReturnPerson/ReturnPerson.js";
import Ratings from "views/pages/Ratings/Ratings.js";
import ReturnMachine from "views/pages/ReturnMachine/ReturnMachine.js";
import Users from "views/pages/Users/Users.js";
import DeviceDetails from "views/pages/DeviceDetails/DeviceDetails.js";
import DeviceLogs from "views/pages/DeviceLogs/DeviceLogs.js";
import DeviceSale from "views/pages/DeviceSale/DeviceSale.js";
import DeviceInfo from "views/pages/DeviceInfo/DeviceInfo.js";
import DeviceConfig from "views/pages/DeviceConfig/DeviceConfig.js";
import ItemDetails from "views/pages/ItemDetails/ItemDetails.js";
import Login from "views/pages/Login.js";

const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    component: Dashboard,
    layout: "/user",
    onSidebar: true,
  },
  {
    path: "/configuration",
    name: "Service Configuration",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-settings-gear-63",
    component: Configuration,
    layout: "/user",
    onSidebar: false,
  },
  {
    collapse: true,
    name: "Return Data",
    rtlName: "صفحات",
    icon: "tim-icons icon-credit-card",
    state: "pagesCollapse",
    onSidebar: true,
    views: [
      {
        path: "/return-person",
        name: "Person Data ",
        rtlName: "عالتسعير",
        mini: "PD",
        rtlMini: "ع",
        component: ReturnPerson,
        layout: "/user",
        onSidebar: true,
      },
      {
        path: "/return-machine",
        name: "Machine Data",
        rtlName: "صودعم رتل",
        mini: "MD",
        rtlMini: "صو",
        component: ReturnMachine,
        layout: "/user",
        onSidebar: true,
      },
    ]
  },
  // {
  //   path: "/users",
  //   name: "Users",
  //   rtlName: "لوحة القيادة",
  //   icon: "tim-icons icon-single-02",
  //   component: Users,
  //   layout: "/user",
  //   onSidebar: true,
  // },
  {
    path: "/device/details/:serial",
    name: "Device Details",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    component: DeviceDetails,
    layout: "/user",
    onSidebar: false,
  },
  {
    path: "/device/sale/:serial",
    name: "Device Sale",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    component: DeviceSale,
    layout: "/user",
    onSidebar: false,
  },
  {
    path: "/device/logs/:serial",
    name: "Device Logs",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    component: DeviceLogs,
    layout: "/user",
    onSidebar: false,
  },
  {
    path: "/device/info/:serial",
    name: "Device Info",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    component: DeviceInfo,
    layout: "/user",
    onSidebar: false,
  },
  {
    path: "/device/config/:serial",
    name: "Device Config",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    component: DeviceConfig,
    layout: "/user",
    onSidebar: false,
  },
  {
    path: "/item/details/:serial/:itemid/:itemName",
    name: "Item Details",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    component: ItemDetails,
    layout: "/user",
    onSidebar: false,
  },
  {
    path: "/login",
    name: "Login",
    rtlName: "هعذاتسجيل الدخول",
    mini: "L",
    rtlMini: "هعذا",
    component: Login,
    layout: "/auth",
    onSidebar: false,
  },
  {
    path: "/ratings",
    name: "Ratings",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-bullet-list-67",
    component: Ratings,
    layout: "/user",
    onSidebar: true,
  },
];

export default routes;
