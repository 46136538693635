import React, { Suspense, useState, useEffect } from 'react';
import SuspenseComponent from '../../../views/components/SuspenseComponent';
import DeviceBreadcrumbComponent from '../DeviceBreadcrumbComponent';
import DeviceLogsTable from './DeviceLogsTable';
import DeviceLogsSubmitDateRange from './DeviceLogsSubmitDateRange';
import { useParams, useLocation } from "react-router-dom";
import { getDeviceLogsByRange } from "API";
import { useMutation, useQueryClient, } from 'react-query'
import { Row, Col } from "reactstrap";
import useSerialCheck from 'hooks/useSerialCheck';
import { useDataDeviceAccess } from 'contexts/DataDeviceContext';
import moment from 'moment';
const DeviceLogs = (props) => {
    let { serial } = useParams();
    const location = useLocation();
    const timestamp = location.state?.timestamp;
    const userDataDeviceAccess = useDataDeviceAccess();
    const serialFound = useSerialCheck(userDataDeviceAccess, serial);
    const [valueDateRange, setValueDateRange] = useState([new Date(new Date().setDate(new Date().getDate() - 7)), new Date()]);

    useEffect(() => {
        if (timestamp !== undefined) {
            setValueDateRange([ new Date(timestamp), new Date(timestamp)])
            setLoading(true);
            const dateStart = moment(timestamp).startOf('day').toDate();
            const dateEnd= moment(timestamp).endOf('day').toDate();
            let startRange = new Date(dateStart);
            let endRange = new Date(dateEnd);
            mutation({ serial: serial, startRange: startRange, endRange: endRange})
        }
    }, [timestamp])

    const [loading, setLoading] = useState(null);
    const queryClient = useQueryClient()
    const handleSubmit = () => {
        if (valueDateRange !== null) {
            if (valueDateRange[0] instanceof Date && valueDateRange[1] instanceof Date) {
                setLoading(true);
                mutation({ serial: serial, startRange: valueDateRange[0], endRange: valueDateRange[1] })
            }
        }
    }

    const { mutate: mutation } = useMutation(
        {
            mutationFn: getDeviceLogsByRange,
            onSuccess: async (newData) => {
                setLoading(false);
                queryClient.setQueryData("Logs", newData);
            }
        }
    );

    return (
        <>
            <div className="content">
                <Row>
                    <Col lg="3">
                        <h4>
                            {serialFound !== true ? null : `${serial} Logs`}
                        </h4>
                    </Col>
                    <Col lg="9">
                        <DeviceBreadcrumbComponent activePath={'DeviceLogs'} serial={serial} />
                    </Col>
                </Row>
                <DeviceLogsSubmitDateRange valueDateRange={valueDateRange} setValueDateRange={setValueDateRange} handleSubmit={handleSubmit} />

                <Suspense fallback={<SuspenseComponent />}>
                    <DeviceLogsTable loading={loading} serial={serial} valueDateRange={valueDateRange} />
                </Suspense>
            </div>
        </>
    );
};

export default DeviceLogs;
